import React from 'react';
import Card from '../components/Card';
import Experiences from '../pictures/icons/experience/Experiences';
import { v4 as uuidv4 } from 'uuid';

function WorkExperience(){
  return(
    <div className="CenterCard">
      {Experiences.map((experience) => {
        return (
          <Card
            key={uuidv4()}
            align="center"
            Img={experience.image}
            Context={experience.description}
            alt={experience.title}
            imgClass="WorkExperience"
            title={experience.title}
            minor={experience.minor}
            location={experience.location}
            contentClass="workExperience"
          />
        )
      })}
    </div>
  )
}

export default WorkExperience;
