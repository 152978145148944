// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDMOZus4ckLIDE5i-kMnC75GbcGTcR8SU8",
    authDomain: "personal-website-33410.firebaseapp.com",
    projectId: "personal-website-33410",
    storageBucket: "personal-website-33410.appspot.com",
    messagingSenderId: "417568118285",
    appId: "1:417568118285:web:d527f314eeea56846d6744",
    measurementId: "G-H6HL9QC7JL"
};

export default firebaseConfig;
