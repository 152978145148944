import firebase from 'firebase/app';
import "firebase/analytics";
import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import firebaseConfig from "./utils/firebaseConfig"

firebase.initializeApp(firebaseConfig);
firebase.analytics();

ReactDOM.render(
    <App />,
  document.querySelector('#root')
);
