import React from 'react';

import Card from '../components/Card';
import ProfilePic from '../pictures/avatar.jpg';
import Context from '../text/AboutMe';
import '../css/styles.css';

function AboutMe(){
  return(
    <div className="CenterCard">
      <Card
        align="center"
        Img={ProfilePic}
        Context={Context}
        alt="About Me"
        class="aboutMe"
        imgClass="circular-portrait"/>
    </div>
  )
}

export default AboutMe;
