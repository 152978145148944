import React from 'react';
import Grid from '@material-ui/core/Grid';
import Picture from './Picture';
import { v4 as uuidv4 } from 'uuid';

function Card(props){
  return(
    <Grid container spacing={1} alignItems={props.align}>
      <Grid item xs={3} >
        <Picture imgurl={props.Img} alt={props.alt} className={props.imgClass}/>
      </Grid>
      <Grid item xs={9} className={props.contentClass}>
        <h3>{props.title}</h3>
        <h4>{props.minor}</h4>
        <h5>{props.location}</h5>
        <div className={props.class}>
          {props.Context.split('\n').map(i => {
            return <p key={uuidv4()}>{i}</p>
          })}
        </div>
      </Grid>
    </Grid>
  )
}

export default Card;
