import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from "react-router-dom";
import GetAppIcon from '@material-ui/icons/GetApp';

function Navbar(){

  function onClick(event) {
    var allLinks = document.querySelectorAll(".link");
    var i;
    for(i = 0; i < allLinks.length; i++){
      allLinks[i].classList.remove("activeLink");
    }
    var activeLink = document.querySelector("#" + event.target.id);
    activeLink.classList.add("activeLink");
  }

  return(
    <div className="navbar">
      <Grid container direction="row" justify="space-evenly" alignItems="center">
        <Grid item>
          <Link id="home" to="/" className="link" onClick={onClick}>
            Home
          </Link>
        </Grid>
        <Grid item>
          <Link id="skills" color="inherit" to="/skills" className="link"  onClick={onClick}>
            Skills
          </Link>
        </Grid>
        <Grid item>
          <Link id="experiences" color="inherit" to="/experiences" className="link"  onClick={onClick}>
            Work Experience
          </Link>
        </Grid>
        <Grid item>
          <Link id="projects" color="inherit" to="/projects" className="link" onClick={onClick}>
            Projects
          </Link>
        </Grid>
        <Grid item
          style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}>
            <GetAppIcon />
            <a href='/Henry_Do_resume.pdf' download className="resume-download">
              Resume
            </a>
        </Grid>
      </Grid>
    </div>
  )
}

export default Navbar;
