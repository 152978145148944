import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {IconButton} from '@material-ui/core';
import {LinkedIn, GitHub, Instagram, Email} from '@material-ui/icons';
import SocialMedia from '../text/SocialMedia';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles(theme => ({
    icon: {
        '&hover': {
          backgroundColor: "black",
        }
      },
    }
));

function Footer() {
  const classes = useStyles();
  function icon(name){
    if(name === "LinkedIn"){
      return <LinkedIn />
    }
    if(name === "Github"){
      return <GitHub />
    }
    if(name === "Instagram"){
      return <Instagram />
    }
    if(name === "Email"){
      return <Email />
    }
  }
  return (
    <footer>
    <div className="footer">
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Grid item>
          <Grid container>
          {SocialMedia.map((link) => {
            return (
            <Grid item key={uuidv4()}>
                <IconButton className={classes.icon} aria-label={link.name} href={link.url}>
                  {icon(link.name)}
                </IconButton>
            </Grid>
          )})}
          </Grid>
        </Grid>
        <Grid item className="footerText">
          Copyright 2021
        </Grid>
      </Grid>
      </div>
    </footer>
  )
}

export default Footer;
