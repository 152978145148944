import React from 'react';
import '../css/styles.css';

function Projects(){
  return(
    <div className="CenterCard">
    Work in progress
    </div>
  )
}

export default Projects;
