import React from "react";
import Navbar from "./Navbar";
import '../css/styles.css';

function Header(){

  return (
    <header>
      <h1 className="name">HENRY DO</h1>
      <Navbar />
    </header>
  )
}

export default Header;
