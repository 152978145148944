const socialMedia = [
  {
    name: "LinkedIn",
    url: "https://www.linkedin.com/in/henry-a-do/"
  },
  {
    name: "Github",
    url: "https://github.com/hdo-00q"
  },
  {
    name: "Instagram",
    url: "https://www.instagram.com/finalretribution/"
  },
  {
    name: "Email",
    url: "mailto:henry.a.do@gmail.com"
  }
]

export default socialMedia;
