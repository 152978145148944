import React from 'react';
import Grid from '@material-ui/core/Grid';
import SkillList from '../pictures/icons/skills/iconSkills';
import '../css/styles.css';
import { v4 as uuidv4 } from 'uuid';

function Skills(){
  return(
    <div className="CenterCard">
      <Grid container spacing={2} justify="space-evenly">
      {SkillList.map((skill) =>{
        return (
          <Grid item key={uuidv4()} xs={3}>
            <img className="icon" src={skill.image} alt={skill.title}/>
          </Grid>
        )
      })}
      </Grid>
    </div>
  )
}

export default Skills;
