import React from 'react';
import '../css/styles.css';

function Picture(props){
  return (
    <img src={props.imgurl} alt={props.alt} className={props.className} />
  )
}

export default Picture;
