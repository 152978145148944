import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

import AboutMe from './AboutMe';
import Skills from './Skills';
import WorkExperience from './WorkExperience';
import Projects from './Projects';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import '../css/styles.css';

function Home() {
  return (
    <div >
      <Router>
      <Header />
      <Switch>
        <Route exact path="/">
          <AboutMe />
        </Route>
        <Route path="/skills">
          <Skills />
        </Route>
        <Route path="/experiences">
          <WorkExperience />
        </Route>
        <Route path="/projects">
          <Projects />
        </Route>
      </Switch>
      <Footer />
      </Router>
    </div>
  );
}

export default Home;
