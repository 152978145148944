import LOGO_XLINK from './Logo_crosslink.jpg';
import LOGO_ARC from './Logo_arc_system_works.png';
import LOGO_TAXPASS from './Logo_Taxpass.png';

const Experiences = [
  {
    title: "Crosslink Professional Tax Solutions",
    minor: "Software Developer, Since June 2013",
    location: "Lathrop, CA",
    image: LOGO_XLINK,
    description: `My first job after college! I was brought in as a JR developer with the expectation that with time I would take ownership of my modules and continue to provide the highest quality experience the software was known for.
    I did not disappoint and overcame challenges of a jr developer such as having to learn a proprietary language, source control, testing, deploying updates, and interdepartmental communication. My team has always been one of the first, if not first, to launch our modules for the software.
    During the off seasons, I developed and assisted in scripts, extensions, web applications using a variety of different tech stacks that resulted in increase productivity of the development cycle by over 30%.`,
  },
  {
    title: "Taxpass",
    minor: "Full Stack Developer, Since June 2017",
    location: "Lathrop, CA",
    image: LOGO_TAXPASS,
    description: `This was a huge initiative being built from the ground up. I was brought in on the project as an individual contributor and here I developed the taxpayer form using the Ionic framwork.
    I transitioned to developing the golang script that would request data from our api and create a readable file for the desktop software where it would then create a tax return using that data. The backend in this process was also developed by me and followed REST conventions.
    As the years went on I continued to develop new features such as downloading photos from the SQL database to the desktop software, increased security to access taxpayer return data, added a return status on their tax return.
    I've been responsible for this process and have become the lead developer over the years. Being the lead in this process I have trained others in the process as well as how to debug issues.`
  },
  {
    title: "Arc System Works",
    minor: "Game Developer Intern, Summer 2011 - Winter 2011",
    location: "Yokohama, JP",
    image: LOGO_ARC,
    description: `My university offered an international co-op program and I was selected for the program because of my passion for gaming and the Japanese culture.
    I landed at Arc System Works; here I was presented challenges of learning the Nintendo 3DS development kit, the internal library created by a team member, memory efficiency for the 3DS, and communication with my team because of the language barrier.`
  }
];

export default Experiences;
