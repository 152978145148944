import ICON_CPLUSPLUS from './Logo_C++.svg';
import ICON_CSHARP from './Logo_CSharp.svg';
import ICON_GO from './Logo_GO.png';
import ICON_IONIC from './Logo_Ionic.png';
import ICON_REACT from './Logo_React.png';
import ICON_JAVASCRIPT from './Logo_Javascript.png';
import ICON_MYSQL from './Logo_mysql.png';
import ICON_REST from './Logo_REST.png';
import ICON_ANGULAR from './Logo_Angular.png';
import ICON_NODE from './Logo_Node.png';
import ICON_TFS from './Logo_TFS.png';
import ICON_GIT from './Logo_Git.png';
import ICON_CSS from './Logo_CSS.png';
import ICON_HTML from './Logo_HTML.png';
import ICON_MONGO from './Logo_MongoDB.png';

const iconSkills = [
  {
    title: "C++",
    image: ICON_CPLUSPLUS
  },
  {
    title: "C#",
    image: ICON_CSHARP
  },
  {
    title: "Javascript",
    image: ICON_JAVASCRIPT
  },
  {
    title: "HTML",
    image: ICON_HTML
  },
  {
    title: "CSS",
    image: ICON_CSS
  },
  {
    title: "GoLang",
    image: ICON_GO
  },
  {
    title: "React",
    image: ICON_REACT
  },
  {
    title: "Ionic",
    image: ICON_IONIC
  },
  {
    title: "Angular",
    image: ICON_ANGULAR
  },
  {
    title: "mySql",
    image: ICON_MYSQL
  },
  {
    title: "MongoDB",
    image: ICON_MONGO
  },
  {
    title: "REST",
    image: ICON_REST
  },
  {
    title: "NodeJS",
    image: ICON_NODE
  },
  {
    title: "Team Foundation Server",
    image: ICON_TFS
  },
  {
    title: "Git",
    image: ICON_GIT
  }
]

export default iconSkills;
